import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import { Blog, Post } from './Blog';

export const ROUTES = {
    home: '/',
    subscribe: '/subscribe',
    post: (id = ':id') => `/post/${id}`,
}

export const InternalRoutes = () => (
    <Router>
        <Routes>
            <Route path={ROUTES.home} element={<Blog/>} />
            <Route path={ROUTES.post()} element={<Post/>} />
        </Routes>
    </Router>
)

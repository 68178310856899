import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import {InternalRoutes} from 'routes';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <InternalRoutes/>
  </ThemeProvider>
  );
}
import React from 'react';
import {Link} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Header, Footer, MainFeaturedPost, Sidebar } from 'components';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {ROUTES} from 'routes';

export interface Options {
  sidebar?: boolean;
  featured?: boolean;
}

const mainFeaturedPost = {
  id: 'blog-post1',
  title: 'Kwixl + Shopify = Easy Live Sales!',
  description:
    "Kwixl is now available as an app for Shopify, making it easy to integrate Live Sales for your store.",
  image: 'https://source.unsplash.com/random',
  imageText: 'main image description',
  linkText: 'Continue reading…',
};

const sidebar = {
  title: 'About',
  description:
    'Kwixl Live Video Commerce is a live video selling platform that allows you to engage with your customers in a fun, interactive way.  In addition, Kwixl works with your Facebook page to allow you to automate "Comment Claim Sales" and turn your Facebook Live videos into an automated sales tool.  Also, Kwixl integrates with Shopify!',
  archives: [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 1999', url: '#' },
    { title: 'October 1999', url: '#' },
    { title: 'September 1999', url: '#' },
    { title: 'August 1999', url: '#' },
    { title: 'July 1999', url: '#' },
    { title: 'June 1999', url: '#' },
    { title: 'May 1999', url: '#' },
    { title: 'April 1999', url: '#' },
  ],
  social: [
    { name: 'Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/kwixl' },
    { name: 'Instagram', icon: InstagramIcon, url: `https://instagram.com/kwixl.live` },
  ],
};

export const withBasePage = (WrappedComponent: React.FC, options: Options = { sidebar: true }) => {
  return () => (
      <Container maxWidth="lg">
        <Link to={ROUTES.home} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Header title="Kwixl Live Video Commerce Blog" image={'https://cdn.kwixl.com/img/logo.png'} sections={[]} />
        </Link>
        {options?.featured && <MainFeaturedPost post={mainFeaturedPost} />}
        {options.sidebar 
          ? (
              <Grid container spacing={5} sx={{ mt: 3 }}>
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{
                    '& .markdown': {
                      py: 3,
                    },
                  }}
                >
                  <WrappedComponent/>
                </Grid>
                  <Sidebar
                    title={sidebar.title}
                    description={sidebar.description}
                    archives={[]}
                    social={sidebar.social}
                  />
                </Grid>
            )
          : (
              <Grid container spacing={5} sx={{ mt: 3 }}>
                <WrappedComponent/>
              </Grid>
            )
        }
        <Footer
          title="Kwixl Live"
          description="Making it easy to create fun, engaging live sales!"
        />
      </Container>
    );
  };

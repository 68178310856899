import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';
//import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

interface HeaderProps {
  sections: ReadonlyArray<{
    title: string;
    url: string;
  }>;
  title: string;
  image?: string;
}

export const Header = (props: HeaderProps) => {

  const { sections, title, image } = props;

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', marginTop:'20px', paddingBottom:'15px' }}>
        <Typography
          component="h2"
          variant="h4"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1, verticalAlign: 'middle' }}
        >
          {image && (
            <Box
              component="img"
              sx={{
                maxWidth: { xs: 60, md: 60 },
                verticalAlign: 'middle',
                marginRight: '10px',
              }}
              alt="The house from the offer."
              src={image}
            />
          )}
          {title}
        </Typography>
        {/*
        <IconButton>
          <SearchIcon />
        </IconButton>
        <Button variant="outlined" size="small">
          Sign up
        </Button>
      */}
        <Button variant="outlined" size="small">
          Subscribe
        </Button>
      </Toolbar>
      <Toolbar
        component="nav"
        variant="dense"
        sx={{ justifyContent: 'space-between', overflowX: 'auto' }}
      >
        {sections.map((section) => (
          <Link
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            sx={{ p: 1, flexShrink: 0 }}
          >
            {section.title}
          </Link>
        ))}
      </Toolbar>
    </>
  );
}

export default Header;
import Main from './Main';
import post2 from './blog-post2';
import { withBasePage } from 'hoc';

const posts = [post2];

export const Blog = withBasePage(() => 
    <Main title="Latest Updates" posts={posts} />, 
    { sidebar: true, featured: true }
);

export default {
    id: 'blog-post2',
    lead: `
# Automating Facebook Comment Claim Sales with Kwixl

_Nov 25, 2022 by [Kwixl](https://kwixl.com)_

If you sell products from your Facebook page, it just got way easier to hold 
Comment Claim Sales (CCS.)
`,
content: `
## What is a CCS?

A Comment Claim Sale is simply a way to sell products on Facebook from your page without 
having to use the Facebook shopping platform (although you can use that as well.)

For a CCS, typically a post highlighting a single product is created, and followers can comment on the post to purchase the item.  However, you can also create a main sale post and 
then list products in the post comments.  Followers can then leave a comment reply on each product comment to purchase the item.

However, **some of the major issues** most sellers face when holding a CCS include: 

- processing all the comments into claims
- creating invoices
- collecting payment

Kwixl simplifies that for you by automating the entire process!

## Posting a CCS From Kwixl

Creating a CCS on Kwixl is quick and easy!

1. From your Dashboard, click on the Facebook tab
2. Click the *New Post* button
3. Select a Facebook Page that you manage
4. Add a title for post
5. Upload an optional image (recommended!)
6. Create your post message, adding any information the viewers need such as how to claim or where to leave a comment
7. Select the level at which comments will be automatically processed by Kwixl
8. Set how long the sale will last (in hours)
9. Select **claim settings**, including an optional **auto-responder**
10 Click Publish, or optionally schedule your post

Kwixl will then take care of publishing your post and then handling claims according to your settings!

## Comment Processing Level

Kwixl automatically processes comments based on which level you choose during your setup.

- **Any Level** - Comments are parsed at any level in the post...on the main thread, as replies to comments, etc.
- **Main Thread** - Only comments that are posted at the main (root) level of the thread are processed.
- **Under Product** - Only comments that are posted as a reply to products are processed.

## Auto-Processing Comments

Kwixl will examine each comment left on your post to determine if someone is trying to claim a product. 

Claims need to include a **claim keyword** such as *claim*, *klm*, *buy*, *take*, *purchase*, etc., as well as the lot number and price for the item.

Example claims could look like:

\`\`\`Claim lot 1 for $10\`\`\`

\`\`\`klm 2 5\`\`\`

## Simplified Claims

To make things more convenient for your customers, you can activate **Simplified Claims**, allowing your customers to claim items with claims such as:

\`\`\`buy 1\`\`\`

\`\`\`purchase 2\`\`\`

This simplified format just includes a claim keyword and the lot number.

## Auto-Accepting Claims

If you have simplified claims activated, you can choose to have Kwixl automatically accept valid claims and add them to the user's invoice.

## Manually Accepting Claims

If you don't want to have claims auto-accepted, you can easily accept valid claims from the **Post Management** page for the post.  This allows you to 
review comments (listed by first received) and accept the comment as a claim.

We hope this has provided some insight into this great new feature for [Kwixl](https://kwixl.com)! Please feel free to [contact us](https://kwixl.com/contact) if you 
have any questions or would like more information!

`
}

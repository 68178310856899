import {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { Markdown } from 'components';
import { withBasePage } from 'hoc';

export const Post = withBasePage(() => {

    const { id } = useParams();

    const [content, setContent] = useState('');
    const [ready, setReady] = useState(false);

    useEffect(() => {
        import(`./${id}`)
            .then(res => {
                console.log('GOT RES', res.default);
                setContent(`${res.default.lead}\n${res.default.content}`);
                setReady(true);
            });
    },[id]);

    if (!ready || !content) return <>Please wait...</>;

    return (
        <main>
            <Grid container spacing={4}>
                <Markdown className="markdown">
                    {content}
                </Markdown>
            </Grid>
        </main>
    )
}, { featured: false, sidebar: true });

import {useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {Markdown} from 'components';
import Link from '@mui/material/Link';
import {ROUTES} from 'routes';

interface MainProps {
  posts: ReadonlyArray<any>;
  title: string;
}

export default function Main(props: MainProps) {
  const { posts, title } = props;

  const navigate = useNavigate();

  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        '& .markdown': {
          py: 3,
        },
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      {posts.map((post) => (
        <>
          <Markdown className="markdown" key={post?.lead?.substring(0, 40)}>
            {post.lead}
          </Markdown>
          <Link variant="subtitle1" href={''} onClick={() => navigate(ROUTES.post(post.id))}>
            Read more...
          </Link>
        </>
      ))}
    </Grid>
  );
}
